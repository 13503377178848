import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';

const ThankYou = () => {
  const [showAnimation, setShowAnimation] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowAnimation(true);
    }, 500); // Delay before starting the animation
  }, []);

  return (
    <div className="flex flex-col justify-center items-center h-screen bg-gray-900 text-white">
      <div className="relative text-center">
        {/* Main Thank You Text */}
        <h1 className={`text-5xl md:text-7xl font-bold mb-4 ${showAnimation ? 'animate-blast-animation' : ''}`}>
          Thank You!
        </h1>

        {/* Additional Content */}
        <p className="text-lg md:text-2xl mt-2">
          Thank you for signing up, one of our representatives will contact you soon!
        </p>

        {/* Contact Information with Icons */}
        <div className="mt-4 flex flex-col items-center space-y-4">
          <div className="flex items-center">
            <FontAwesomeIcon icon={faPhone} className="text-pink-500 mr-2" />
            <span className="text-lg md:text-xl">346-264-2066</span>
          </div>
          <div className="flex items-center">
            <FontAwesomeIcon icon={faEnvelope} className="text-blue-500 mr-2" />
            <span className="text-lg md:text-xl">support@theonlineclasshelper.com</span>
          </div>
        </div>

        {/* Modified Blast Effect */}
        {showAnimation && (
          <div className="absolute inset-0 flex justify-center items-center">
            <div className="absolute w-20 h-20 bg-gradient-to-r from-blue-400 to-pink-500 rounded-full animate-ping opacity-60"></div>
            <div className="absolute w-32 h-32 bg-gradient-to-r from-pink-400 to-yellow-500 rounded-full animate-ping opacity-60 delay-100"></div>
            <div className="absolute w-48 h-48 bg-gradient-to-r from-yellow-400 to-green-500 rounded-full animate-ping opacity-60 delay-200"></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ThankYou;
