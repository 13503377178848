import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Logo } from '../assets';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="bg-gradient-to-r from-headerBg to-headerBgDark text-white shadow-md">
      <nav className="container mx-auto flex justify-between items-center py-4">
        <div className="flex items-center">
          <Link to="/">
            <img src={Logo} alt="Logo" className="max-w-28 mr-3" />
          </Link>
        </div>
        <div className="hidden md:flex space-x-6 items-center font-semibold">
          <Link to="/services" className="hover:text-gray-400">Services</Link>
          <Link to="/online-exam" className="hover:text-gray-400">Online Exam</Link>
          <Link to="/what-we-do" className="hover:text-gray-400">What We Do</Link>
          <Link to="/subjects" className="hover:text-gray-400">Subjects</Link>
          <Link to="/testimonials" className="hover:text-gray-400">Testimonials</Link>
          <Link to="/contact-us" className="hover:text-gray-400">Contact Us</Link>
          <button className="bg-headerBg text-white px-4 py-2 rounded hover:bg-red-600">
          346-264-2066
      </button>
        </div>
        <div className="md:hidden">
          <button onClick={toggleMenu} className="focus:outline-none px-4">
            <svg
              className="w-6 h-6 text-white"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              {isMenuOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              )}
            </svg>
          </button>
        </div>
      </nav>
      {isMenuOpen && (
        <div className="md:hidden bg-gradient-to-r from-headerBg to-headerBgDark text-white shadow-md">
          <nav className="flex flex-col items-center space-y-4 py-4">
            <Link to="/services" className="hover:text-gray-400" onClick={toggleMenu}>Services</Link>
            <Link to="/online-exam" className="hover:text-gray-400" onClick={toggleMenu}>Online Exam</Link>
            <Link to="/what-we-do" className="hover:text-gray-400" onClick={toggleMenu}>What We Do</Link>
            <Link to="/subjects" className="hover:text-gray-400" onClick={toggleMenu}>Subjects</Link>
            <Link to="/testimonials" className="hover:text-gray-400" onClick={toggleMenu}>Testimonials</Link>
            <Link to="/contact-us" className="hover:text-gray-400" onClick={toggleMenu}>Contact Us</Link>
          </nav>
        </div>
      )}
    </header>
  );
};

export default Header;
