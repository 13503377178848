import React from 'react';

const fieldsOfStudy = [
  'Accounting & Finance',
  'Agriculture & Forestry',
  'Architecture',
  'Art & Design',
  'Biological Sciences',
  'Business Management',
  'Chemistry',
  'Communication & Media Studies',
  'Computer Science',
  'Dentistry',
  'Drama, Dance & Cinematics',
  'Economics',
];

const FieldOfStudy = () => {
  return (
    <div className="container mx-auto py-16 px-4 text-center">
      <h2 className="text-5xl font-bold mb-4">Experience The Journey</h2>
      <p className="text-xl font-semibold mb-8">Choose Your Field of Study and Get Started!</p>
      <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-8 mb-8">
        {fieldsOfStudy.map((field, index) => (
          <div key={index} className="flex items-center justify-center">
            <div className={`relative flex items-center justify-center w-32 h-32 border-4 rounded-full ${index % 2 === 0 ? 'border-headerBgDark' : 'border-transparent'}`}>
              <div className={`absolute top-0 left-0 w-32 h-32 ${index % 2 === 0 ? 'border-t-4 border-l-4 border-headerBgDark' : 'border-b-4 border-r-4 border-red-500'} rounded-full`}></div>
              <span className="text-center z-10">{field}</span>
            </div>
          </div>
        ))}
      </div>
      <button className="bg-headerBgDark text-white px-6 py-2 rounded hover:bg-red-600">Read More</button>
    </div>
  );
};

export default FieldOfStudy;
