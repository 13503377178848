import React, { useState, useEffect, useRef } from 'react';
import { onlineClass, OnlineCourse, onlineexam, onlineTest } from '../assets';

const ExamServiceSlider = () => {
  const items = [
    {
      title: "Online Class",
      image: onlineClass,
    },
    {
      title: "Online Exam",
      image: onlineexam,
    },
    {
      title: "Online Test",
      image: onlineTest,
    },
    {
      title: "Online Assignment",
      image: onlineClass,
    },
    {
      title: "Online Course",
      image: OnlineCourse,
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [slidesToShow, setSlidesToShow] = useState(window.innerWidth < 768 ? 1 : 3); // Default to 3 slides on larger screens
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [translate, setTranslate] = useState(0);
  const sliderRef = useRef(null);

  const updateSize = () => {
    setSlidesToShow(window.innerWidth < 768 ? 1 : 3);
  };

  useEffect(() => {
    window.addEventListener('resize', updateSize);
    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, []);

  const prevSlide = () => {
    setCurrentIndex(prevIndex => prevIndex > 0 ? prevIndex - 1 : items.length - 1);
  };

  const nextSlide = () => {
    setCurrentIndex(prevIndex => prevIndex < items.length - 1 ? prevIndex + 1 : 0);
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - sliderRef.current.offsetLeft);
    setTranslate(sliderRef.current.scrollLeft);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const x = e.pageX - sliderRef.current.offsetLeft;
    const walk = (x - startX) * 2; // speed of dragging
    sliderRef.current.scrollLeft = translate - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <div className="w-full flex justify-center items-center">
      {/* Left Arrow */}
      <button
        onClick={prevSlide}
        className="text-gray-800 hover:text-black text-2xl p-4"
      >
        ❮
      </button>
  
      {/* Carousel */}
      <div
        className="w-full sm:w-3/4 overflow-hidden"
        ref={sliderRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        style={{
          cursor: isDragging ? "grabbing" : "grab",
        }}
      >
        <div
          className="flex transition-transform duration-300 ease-in-out"
          style={{
            transform: `translateX(-${currentIndex * (100 / slidesToShow)}%)`,
          }}
        >
          {items.map((item, index) => (
            <div
              key={index}
              className="p-4 transition-transform transform hover:scale-105"
              style={{ minWidth: `${100 / slidesToShow}%` }}
            >
              <div className="relative h-64 bg-gray-100 rounded-lg shadow-lg overflow-hidden">
                <img
                  src={item.image}
                  alt={item.title}
                  className="w-full h-full object-cover"
                />
                <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2">
                  {item.title}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
  
      {/* Right Arrow */}
      <button
        onClick={nextSlide}
        className="text-gray-800 hover:text-black text-2xl p-4"
      >
        ❯
      </button>
    </div>
  );
};

export default ExamServiceSlider;
