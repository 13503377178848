import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Home from './pages/Home';
import Footer from './components/Footer';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { config } from '@fortawesome/fontawesome-svg-core';
import { Signup } from './pages/Signup';
import Services from './components/Services';
import PromoSection from './components/PromoSection';
import FieldOfStudy from './components/FieldOfStudy';
import Testimonials from './components/Testimonials';
import ContactUsSection from './components/ContactUsSection';
import TermsAndConditions from './components/TermsAndConditions';
import ThankYou from './components/ThankYou';
import { Exam } from './pages/Exam';
config.autoAddCss = false;



function App() {
  return (
    <Router basename="/">
      <div className="min-h-screen flex flex-col">
        <Header />
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/services" element={<Services />} />
            <Route path="/what-we-do" element={<PromoSection />} />
            <Route path="/about" element={<PromoSection />} />
            <Route path="/subjects" element={<FieldOfStudy />} />
            <Route path="/testimonials" element={<Testimonials />} />
            <Route path="/contact-us" element={<ContactUsSection />} />
            <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
            <Route path="/thank-you" element={<ThankYou />} />
            <Route path="/online-exam" element={<Exam />} />
            
            
       
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
