import React, { useState } from "react";
import axios from "axios";
import { BgHero } from "../assets";
import { useNavigate } from "react-router-dom";

const Hero = () => {

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
   
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/send-email`, formData);
      alert("Message sent successfully");
      navigate('/thank-you');
      
      console.log('URL',process.env.REACT_APP_BACKEND_URL);
      setFormData({ name: "", email: "", phone: "", message: "" });
    } catch (error) {
      console.error("Error sending email", error);
      alert("Failed to send message");
      console.log('URL',process.env.REACT_APP_BACKEND_URL);

    }
  };

  return (
    <div
      className="relative flex items-center justify-center h-screen bg-cover bg-center"
      style={{ backgroundImage: `url(${BgHero})` }}
    >
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="container mx-auto relative z-10 text-white p-6">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="md:w-1/2 mb-8 md:mb-0 text-center md:text-left">
            <h1 className="lg:text-5xl md:text-2xl text-xl sm:text-lg font-bold mb-4">
              Succeed in Online Classes Today!
            </h1>
            <p className="text-lg mb-6">
              Can I hire someone to take my online class for me? Or typing “to
              take my class for me?” Well, you’ve come to the right place. In
              any case, be it taking your online classes or helping with online
              classes, Us Online Class Helper is here for you.
            </p>
            <div className="flex justify-center md:justify-start space-x-4">
              <button className="bg-white text-black px-4 py-2 rounded hover:bg-gray-200">
                Live Chat
              </button>
              <button className="bg-white text-black px-4 py-2 rounded hover:bg-gray-200">
                Call Us
              </button>
            </div>
          </div>
          <div className="bg-white text-black p-6 rounded shadow-md md:w-1/3">
            <h2 className="text-2xl font-bold mb-4">Avail 70% Discount Now</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Name"
                className="w-full p-2 border border-headerBgDark rounded"
                required
              />
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email"
                className="w-full p-2 border border-headerBgDark rounded"
                required
              />
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="Phone No"
                className="w-full p-2 border border-headerBgDark rounded"
                required
              />
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Message"
                className="w-full p-2 border border-headerBgDark rounded"
                required
              ></textarea>
              <button
                type="submit"
                className="w-full bg-headerBg text-white p-2 rounded hover:bg-red-600"
              >
                Sign Up Now
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
