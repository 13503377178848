import React from 'react'
import ClassHero from '../components/ClassHero'
import ExamServiceSlider from '../components/ExamServiceSlider'
import PromoSection from '../components/PromoSection'
import SupportSection from '../components/SupportSection'
import FieldOfStudy from '../components/FieldOfStudy'
import PromoBanner from '../components/PromoBanner'
import AcademicSuccess from '../components/AcademicSuccess'
import Testimonials from '../components/Testimonials'
import ContactUsSection from '../components/ContactUsSection'

export const Exam = () => {
  return (
    <div>
        <ClassHero />
        <ExamServiceSlider className="-mt-28" />
        <PromoSection />
        <SupportSection />
        <FieldOfStudy />
        <PromoBanner />
        <AcademicSuccess />
        <Testimonials />
        <ContactUsSection />
    </div>
  )
}
