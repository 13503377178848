import React, { useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhoneAlt, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const ContactUsSection = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [message, setMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/send-email`, formData);
      alert("Message sent successfully");
      navigate('/thank-you');
      setFormData({ name: "", email: "", phone: "", message: "" });
    } catch (error) {
      console.error("Error sending email", error);
      alert("Failed to send message");
    }
  };

  return (
    <div className="bg-gradient-to-r from-purple-500 to-red-500 py-16 px-4 text-white">
      <div className="container mx-auto flex flex-col lg:flex-row items-start justify-between space-x-4">
        <div className="w-full lg:w-1/2 space-y-6 mb-8 lg:mb-0">
          <div className="bg-white bg-opacity-10 p-6 rounded-lg shadow-md flex items-center space-x-4">
            <FontAwesomeIcon icon={faEnvelope} className="text-red-300 text-4xl" />
            <div>
              <p className="text-gray-200">Email Us</p>
              <p className="font-bold">info@usonlineclassHelper.com</p>
            </div>
          </div>
          <div className="bg-white bg-opacity-10 p-6 rounded-lg shadow-md flex items-center space-x-4">
            <FontAwesomeIcon icon={faPhoneAlt} className="text-red-300 text-4xl" />
            <div>
              <p className="text-gray-200">Call Us</p>
              <p className="font-bold">346-264-2066</p>
            </div>
          </div>
          <div className="bg-white bg-opacity-10 p-6 rounded-lg shadow-md flex items-center space-x-4">
            <FontAwesomeIcon icon={faMapMarkerAlt} className="text-red-300 text-4xl" />
            <div>
              <p className="text-gray-200">Visit Us</p>
              <p className="font-bold">35 Sterling Pl, Brooklyn, NY 11217, USA</p>
            </div>
          </div>
          <div className="flex justify-center mt-4">
            <button
              className="bg-white bg-opacity-10 text-white px-6 py-2 rounded-lg hover:bg-opacity-20 transition duration-300"
            >
              View on Map
            </button>
          </div>
        </div>
        <div className="w-full lg:w-1/2 bg-white bg-opacity-10 p-8 rounded-lg shadow-lg">
          <h2 className="text-2xl font-bold mb-4">Avail 70% Discount Now</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-gray-300 text-sm font-bold mb-2" htmlFor="name">
                Name
              </label>
              <input
                className="w-full text-black px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-red-500"
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter your name"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-300 text-sm font-bold mb-2" htmlFor="email">
                Email
              </label>
              <input
                className="w-full text-black px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-red-500"
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter your email"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-300 text-sm font-bold mb-2" htmlFor="phone">
                Phone No
              </label>
              <input
                className="w-full text-black px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-red-500"
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="Enter your phone number"
              />
            </div>
            <div className="mb-6">
              <label className="block text-gray-300 text-sm font-bold mb-2" htmlFor="message">
                Message
              </label>
              <textarea
                className="w-full text-black px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-red-500"
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Enter your message"
                rows="4"
              ></textarea>
            </div>
            <button
              type="submit"
              className="w-full bg-headerBg text-white px-4 py-2 rounded-lg hover:bg-red-600"
            >
              Sign Up Now
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUsSection;
